import React from 'react';

import TemplateDashboard from '../../../components/TemplateDashboard';
import GestionDocumentosCreadoresContent from './components/GestionDocumentosCreadoresContent';
// import "../../assets/css/master.css";


const GestionDocumentosCreadores = () => {

    return (
        <>

            <TemplateDashboard>
                <GestionDocumentosCreadoresContent />
            </TemplateDashboard>

        </>
    )
};

export default GestionDocumentosCreadores;