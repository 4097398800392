import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";

import LoadingComponent from "../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
// import Swal from "sweetalert2";
import { Stack } from "@mui/material";
import { Box, Button, Chip, Divider, Link, Typography } from "@material-ui/core";
import DataGridInnovattio, { renderCellDate } from "../../../components/DataGridInnovatiio";
import QuickSearchToolbar from "../../../components/QuickSearchToolbar";
import { requestSearch } from "../../../utils/regExp";
import { eliminar_documento_creadores, getDocumentos, insertarDocumentoCreador } from "../../../services/alumnos";
import { DialogDocumentoForm } from "./DialogDocumentoForm";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { blue, green, grey, red, yellow } from "@material-ui/core/colors";
import Swal from "sweetalert2";
import useDataGridFilterPersist from "../../../hooks/useDataGridFilterPersist";
import { DialogCreadorForm } from "./DialogCreadorForm";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { render } from "@testing-library/react";
import { format } from 'date-fns';
import DateFnsUtils from "@date-io/date-fns";

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
  },
  emptyImage: {
    width: "100%",
  },

  enlace: {
    color: theme.palette.link.main,
    textDecoration: "none",
  },
}));

export default function DocumentosContent() {
  const classes = useStyles();

  const [documentos, setDocumentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [openModalCreador, setOpenModalCreador] = useState(false);
  const [documentoIdSeleccionado, setDocumentoIdSeleccionado] = useState(null);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const history = useHistory();

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando documentos");

    cargarDocumentos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                              Cargar Plantillas                             */
  /* -------------------------------------------------------------------------- */
  const cargarDocumentos = () => {

    getDocumentos({usuario_id: usuarioSeleccionado.id}).then( response => {
      setLoading(false);
      response && setDocumentos(response.documentos);
    })

  };

  // const CheckUrl = (url) => {
  //   console.log(url.url)
  //   const [status, setStatus] = useState(null);
  
  //   const handleCheckUrl = async () => {
  //     try {
  //       // Hacemos una petición HEAD para no descargar el contenido completo
  //       const response = await fetch(url.url, { method: 'HEAD' });
  //       // Si response.ok es true, significa que la respuesta fue 2xx (200-299)
  //       console.log(response)
  //       setStatus(response.ok ? 'existe' : 'no-existe');
  //     } catch (error) {
  //       // Aquí caemos si fetch lanza una excepción (e.g. error de red)
  //       setStatus('error');
  //     }
  //   };
    
  //   useEffect(() => {
  //     handleCheckUrl();
  //   } , [url]);

  //   return (
  //     <div style={{ margin: '20px' }}>
  //       {status === 'existe' && <p style={{ color: 'green' }}>La URL existe (respuesta 2xx)</p>}
  //       {status === 'no-existe' && <p style={{ color: 'red' }}>La URL no existe o devuelve error</p>}
  //       {status === 'error' && <p style={{ color: 'red' }}>Error al verificar la URL</p>}
  //     </div>
  //   );
  // }
  
  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */
  const columnas = [
    { field: "id", hide: true, headerName: "ID", minWidth: 10 },
    { field: "tipo_nombre", headerName: "Tipo", minWidth: 150 },
    { field: "curso_nombre", headerName: "Curso", minWidth: 150 },
    { field: "orden", headerName: "Clase", minWidth: 150, renderCell: (cellValues) => {
      return (
        cellValues.row.orden === "0" ? "" : cellValues.row.orden
        );
      } 
    },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", minWidth: 150 },
    { field: "especialidad_nombre", headerName: "Especialidad", minWidth: 150 },
    { field: "fecha_inicio", headerName: "Fecha inicio", minWidth: 170 },
    { field: "fecha_limite", headerName: "Fecha límite", minWidth: 170 },
    { field: "nombre", headerName: "Nombre", minWidth: 370 },
    { field: "url", headerName: "URL", flex: 1, minWidth: 370, renderCell: (cellValues) => {
      
      return (
        <> 
          { cellValues.row.num_compartidos > 0 && <PeopleAltOutlinedIcon style={{color: grey[500], marginRight: 10}} /> }
          {
            // <CheckUrl url={cellValues.row.url} />
            cellValues.row.url && <Link href={cellValues.row.url} target="_blank" rel="noreferrer" style={{color: 'blue'}}>{cellValues.row.url}</Link>
          }    
        </>
        )
        }
    },
    { 
      field: "creadores", 
      headerName: "Creadores", 
      cellClassName: 'columna-gris',
      minWidth: 200 
    },
    { 
      field: "creacion_estado", 
      headerName: "Estado creacion", 
      minWidth: 150, 
      valueGetter: (cellValues) => {
        let resultado = ""

        if (cellValues.row.creacion_num_items_terminado     > 0) { resultado += " Terminado " }
        if (cellValues.row.creacion_num_items_en_correccion > 0) { resultado += " En corrección " }
        if (cellValues.row.creacion_num_items_pendiente     > 0) { resultado += " Pendiente " }
        if (cellValues.row.creacion_num_items_en_proceso   >= 1) { resultado += " En proceso " }

        return resultado
      },
      cellClassName: 'columna-gris',
      renderCell: (cellValues) => {
        return (
          <>
            { cellValues.row.creacion_num_items_terminado     > 0 && <Chip label={`Ter: ${cellValues.row.creacion_num_items_terminado}/${cellValues.row.num_creadores}`} size="small" style={{backgroundColor: green[400], color: 'white'}}></Chip>} 
            { cellValues.row.creacion_num_items_en_correccion > 0 && <Chip label={`Cor: ${cellValues.row.creacion_num_items_en_correccion}/${cellValues.row.num_creadores}`} size="small" style={{backgroundColor: yellow[400], color: 'black'}}></Chip>} 
            { cellValues.row.creacion_num_items_pendiente     > 0 && <Chip label={`Pen: ${cellValues.row.creacion_num_items_pendiente}/${cellValues.row.num_creadores}`} size="small" style={{backgroundColor: red[400], color: 'white'}}></Chip>} 
            { cellValues.row.creacion_num_items_en_proceso >= 1 && <Chip label={`Pro: ${cellValues.row.creacion_num_items_en_proceso}/${cellValues.row.num_creadores}`} size="small" style={{backgroundColor: blue[400], color: 'white'}}></Chip>}
            { cellValues.row.num_creadores === "0" && <Chip label="Sin creadores" size="small" style={{backgroundColor: grey[400], color: 'white'}}></Chip>} 
          </>
        )
      } 
    },
    { field: "revisores", headerName: "Revisores", minWidth: 200 },
    { 
      field: "estado_revision", 
      headerName: "Estado revision", 
      minWidth: 150, 
      valueGetter: (cellValues) => {
        let resultado = ""

        if (cellValues.row.revision_num_items_terminado     > 0) { resultado += " Terminado " }
        if (cellValues.row.revision_num_items_pendiente     > 0) { resultado += " Pendiente " }
        if (cellValues.row.revision_num_items_en_proceso   >= 1) { resultado += " En proceso " }

        return resultado
      },
      renderCell: (cellValues) => {
        return (
          <>
            { cellValues.row.revision_num_items_terminado     > 0 && <Chip label={`Ter: ${cellValues.row.revision_num_items_terminado}/${cellValues.row.num_revisores}`} size="small" style={{backgroundColor: green[400], color: 'white'}}></Chip>} 
            { cellValues.row.revision_num_items_pendiente     > 0 && <Chip label={`Pen: ${cellValues.row.revision_num_items_pendiente}/${cellValues.row.num_revisores}`} size="small" style={{backgroundColor: red[400], color: 'white'}}></Chip>} 
            { cellValues.row.revision_num_items_en_proceso >= 1 && <Chip label={`Pro: ${cellValues.row.revision_num_items_en_proceso}/${cellValues.row.num_revisores}`} size="small" style={{backgroundColor: blue[400], color: 'white'}}></Chip>}
            { cellValues.row.num_revisores === "0" && <Chip label="Sin creadores" size="small" style={{backgroundColor: grey[400], color: 'white'}}></Chip>} 
          </>
        )
      } 
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          label="Ver usuarios asignados"
          onClick={ () => { 
            history.push(`/gestion-documentos/creadores/${params.id}`);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Añadir usuario"
          onClick={ () => { 
            setDocumentoIdSeleccionado(params.id)
            setOpenModalCreador(true)
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Añadir revisión (Pablo)"
          onClick={ () => { 
            insertarDocumentoCreador({
                      usuario_id: usuarioSeleccionado.id, 
                      creador_id: 37, // Pablo 
                      documento_id: params.id, 
                      funcion_id: 259, // Revision
                      estado: 241, // Pendiente 
                      fecha_limite: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                      descripcion: "Revisión del documento"
                    }).then( response => {
            
                    })
                    
                    cargarDocumentos()
            // setDocumentoIdSeleccionado(params.id)
            // setOpenModalCreador(true)
          }}
          showInMenu
        />,
        <GridActionsCellItem divider showInMenu/>,
        <GridActionsCellItem
          label="Eliminar documento"
          onClick={ () => { 
            Swal.fire({
              title: '¿Realmente deseas eliminar el documento? La operación no se puede deshacer.',
              showCancelButton: true,
              confirmButtonText: 'Eliminar',
              cancelButtonText: `Cancelar`,
              confirmButtonColor: '#afcb0d',
              cancelButtonColor: '#a5a5a5',
              icon: 'warning',
              reverseButtons: true
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                eliminar_documento_creadores({usuario_id: usuarioSeleccionado.id, documento_id: params.id}).then( response => {
                  cargarDocumentos()
                })
              } else if (result.isDenied) {
              //   Swal.fire('Changes are not saved', '', 'info')
              }
            })

            
          }}
          showInMenu
        />,
        // <GridActionsCellItem
        //   label="Pasar a histórico"
        //   onClick={ () => { 
        //   }}
        //   showInMenu
        // />,
      ],
    }
  ];

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */

  const Contenido = ({}) => {
    const [rows, setRows] = useState(documentos);
    const [searchText, setSearchText] = useState("");
    const [openModalDocumento, setOpenModalDocumento] = useState(false);
    const [documentoSeleccionado, setDocumentoSeleccionado] = useState(false);
    
    const handleCloseDialogDocumento = () => {
      setOpenModalDocumento(false)
      cargarDocumentos()
    }
    const handleCloseDialogCreador = () => {
      setOpenModalCreador(false)
      cargarDocumentos()
    }
    const [filterModel, onFilterModelChange] = useDataGridFilterPersist('documentos_page','documentos')

    return (
      <>
        <Stack direction={"row"} spacing={2}>
          <Box mt={0} mb={0} mx={2}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Gestión de documentos
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setDocumentoSeleccionado(null);
              setOpenModalDocumento(true)}
            }
          >
            Añadir nuevo documento
          </Button>
        </Stack>

        
        <Box
          sx={{
            height: 300,
            width: '100%',
            '& .columna-gris': {
              backgroundColor: '#f5f5f5',
            },
          }}
        >          
          <div style={{ height: 700, width: "100%", padding: "0 15px" }}>
            <DataGridInnovattio
              rows={rows}
              columns={columnas}
              rowHeight={30}
              components={{ Toolbar: QuickSearchToolbar }}
              onDoubleClick={(item) => {
                setDocumentoSeleccionado(item);
                setOpenModalDocumento(true)
              }}
              filterModel={ filterModel }
              onFilterModelChange={ onFilterModelChange }
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) =>
                    requestSearch(
                      documentos,
                      setSearchText,
                      setRows,
                      event.target.value
                    ),
                  clearSearch: () =>
                    requestSearch(documentos, setSearchText, setRows, ""),
                },
              }}
            />

            <DialogCreadorForm
              openModal={openModalCreador}
              handleOpenModal={handleCloseDialogCreador}
              creador={null}
              documento_id={documentoIdSeleccionado}
            />
            <DialogDocumentoForm
              openModal={openModalDocumento}
              setOpenModal={setOpenModalDocumento}
              handleCloseDialog={handleCloseDialogDocumento}
              documentoSeleccionado={documentoSeleccionado}
            />

          </div>
        </Box>
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido />
      )}
    </div>
  );
}
