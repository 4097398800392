import DateFnsUtils from "@date-io/date-fns";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import { useEffect, useState } from "react";
import { actualizarDocumentoCreador, getUsuariosCreadores, insertarDocumentoCreador } from "../../../services/alumnos";
import { getListaDetalle } from "../../../services/lista_detalle";
import { Autocomplete, Stack } from "@mui/material";
import { id } from "date-fns/locale";

export const DialogCreadorForm = ({
  openModal,
  handleOpenModal,
  creador,
  documento_id
}) => {
  const [usuariosCreadores, setUsuariosCreadores] = useState([])
  const [funcionesCreadores, setFuncionesCreadores] = useState([])
  const [estadosDocumentos, setEstadosDocumentos] = useState([])

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  useEffect(() => {
    getUsuariosCreadores({usuario_id: usuarioSeleccionado.id}).then( response => {
      setUsuariosCreadores(response.usuarios)
    })

    getListaDetalle({usuario_id: usuarioSeleccionado.id, lista_id: '27'}).then( (response) => {
        setFuncionesCreadores(response.lista_detalle)
    })

    getListaDetalle({usuario_id: usuarioSeleccionado.id, lista_id: '28'}).then( (response) => {
        setEstadosDocumentos(response.lista_detalle)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  useEffect(() => {
    if(creador) {
        formik.setValues({
            documento_id: documento_id,
            documento_creador_id: creador.id,
            funcion_id: creador.funcion_id,
            fecha_limite: creador.fecha_limite,
            estado: creador.estado,
            descripcion: creador.descripcion
        })
    } else {
        formik.setValues({
          documento_id: documento_id,
          documento_creador_id: "",
          usuario_id: "",
          funcion_id: "",
          estado: "",
          fecha_limite: null,
          descripcion: ""
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creador]);

  useEffect(() => {
    if(creador && usuariosCreadores.length > 0) {
      const creadorSeleccionado = usuariosCreadores.find(usr => 
        parseInt(usr.id) === parseInt(creador.usuario_id)
      );
      
      if (creadorSeleccionado) {
        formik.setFieldValue('usuario_id', {
          id: creadorSeleccionado.id,
          label: `${creadorSeleccionado.nombre} ${creadorSeleccionado.apellidos}`
        }, false); // Añade false como tercer parámetro para evitar validación
      }
    }
  }, [creador, usuariosCreadores]);

  /* -------------------------------------------------------------------------- */
  /*                                   Formik                                   */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      documento_creador_id: creador ? creador.id : "",
      documento_id: documento_id,
      usuario_id: "",
      funcion_id: "",
      estado: "",
      fecha_limite: null,
      descripcion: ""
    },

    validationSchema: yup.object({
      // usuario_id: yup.string().required("Obligatorio"),
      usuario_id: yup.mixed().required("Obligatorio"),
      funcion_id: yup.string().required("Obligatorio"),
      estado: yup.string().required("Obligatorio"),
      fecha_limite: yup.string().nullable().required("Obligatorio"),
      descripcion: yup.string()
    }),
    onSubmit: (values) => {

      if(creador) {
        actualizarDocumentoCreador({
          usuario_id: usuarioSeleccionado.id, 
          documento_creador_id: creador.id,
          creador_id: values.usuario_id.id, 
          documento_id: documento_id, 
          funcion_id: values.funcion_id,
          estado: values.estado, 
          fecha_limite: values.fecha_limite,
          descripcion: values.descripcion
        }).then( response => {
        })
      } else {

        insertarDocumentoCreador({
          usuario_id: usuarioSeleccionado.id, 
          creador_id: values.usuario_id.id, 
          documento_id: documento_id, 
          funcion_id: values.funcion_id,
          estado: values.estado, 
          fecha_limite: values.fecha_limite,
          descripcion: values.descripcion 
        }).then( response => {

        })

      }

      handleOpenModal()

    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <Dialog
      open={openModal}
      onClose={() => handleOpenModal()}
      maxWidth={"md"}
    >
      <form
        noValidate
        method="POST"
        enctype="multipart/form-data"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <DialogTitle>Asignar usuario</DialogTitle>
        <DialogContent style={{ width: "700px" }}>
          <Grid container>

            <Grid item xs={12}>
            <Autocomplete
              id="usuario_id"
              onChange={(_, newValue) => {
                formik.setFieldValue("usuario_id", newValue || "");
              }}
              value={formik.values.usuario_id || ""}
              options={usuariosCreadores
                .filter(usuario => usuario.es_creador === "1")
                .map((option) => ({
                  id: option.id,
                  label: `${option.nombre} ${option.apellidos}`
                }))}
              getOptionLabel={(option) => {
                if (typeof option === 'string') return '';
                return option?.label || '';
              }}
              isOptionEqualToValue={(option, value) => {
                if (!option || !value) return true;
                return option.id === value.id;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="usuario_id"
                  label="Usuario"
                  variant="outlined"
                  error={formik.touched.usuario_id && Boolean(formik.errors.usuario_id)}
                  helperText={formik.touched.usuario_id && formik.errors.usuario_id}
                />
              )}
            />  
            </Grid>

            {/* <Grid item sm={12}>
              <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 16 }}
                  error={
                    formik.touched.usuario_id &&
                    Boolean(formik.errors.usuario_id)
                  }
                >
                  <InputLabel id="usuario_id-label">Creador</InputLabel>
                  <Select
                    labelId="usuario_id-label"
                    id="usuario_id"
                    name="usuario_id"
                    label="Creador"
                    fullWidth
                    value={formik.values.usuario_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.usuario_id &&
                      Boolean(formik.errors.usuario_id)
                    }
                  >
                    
                    {usuariosCreadores &&
                      usuariosCreadores.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {`${item.nombre} ${item.apellidos}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {formik.touched.usuario_id &&
                      formik.errors.usuario_id}
                  </FormHelperText>
                </FormControl>
            </Grid> */}

            <Grid item sm={12}>
              <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 16 }}
                  error={
                    formik.touched.funcion_id &&
                    Boolean(formik.errors.funcion_id)
                  }
                >
                  <InputLabel id="funcion_id-label">Funcion</InputLabel>
                  <Select
                    labelId="funcion_id-label"
                    id="funcion_id"
                    name="funcion_id"
                    label="Funcion"
                    fullWidth
                    value={formik.values.funcion_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.funcion_id &&
                      Boolean(formik.errors.funcion_id)
                    }
                  >
                    
                    {funcionesCreadores &&
                      funcionesCreadores.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {`${item.nombre}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {formik.touched.funcion_id &&
                      formik.errors.funcion_id}
                  </FormHelperText>
                </FormControl>
            </Grid>

            <Grid item sm={12}>
              <Stack spacing={2} direction={"row"} style={{ marginTop: 16 }}>
                <FormControl
                    variant="outlined"
                    fullWidth
                    error={
                      formik.touched.estado &&
                      Boolean(formik.errors.estado)
                    }
                  >
                    <InputLabel id="estado-label">Estado</InputLabel>
                    <Select
                      labelId="estado-label"
                      id="estado"
                      name="estado"
                      label="Estado"
                      fullWidth
                      value={formik.values.estado}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.estado &&
                        Boolean(formik.errors.estado)
                      }
                    >
                      
                      {estadosDocumentos &&
                        estadosDocumentos.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {`${item.nombre}`}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>
                      {formik.touched.estado &&
                        formik.errors.estado}
                    </FormHelperText>
                </FormControl>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                    
                        fullWidth
                        id="fecha_limite"
                        label="Fecha Límite"
                        placeholder='Introduce la fecha límite'
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={formik.values.fecha_limite}
                        onChange={value => formik.setFieldValue("fecha_limite", value)}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                        error={formik.touched.fecha_limite && Boolean(formik.errors.fecha_limite)}
                        helperText={formik.touched.fecha_limite && formik.errors.fecha_limite}
                    />
                </MuiPickersUtilsProvider>
              </Stack>
            </Grid>

            <Grid item sm={12} style={{marginTop: 8}} spacing={2}>
              <TextField
                id="descripcion"
                name="descripcion"
                label="Descripcion"
                placeholder="Introduce la descripcion"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.descripcion && Boolean(formik.errors.descripcion)
                }
                helperText={formik.touched.descripcion && formik.errors.descripcion}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginTop: 8 }}
              />               
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpenModal()}>Cancelar</Button>
          <Button type="submit">Asignar usuario</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
